import React from "react";
import MainContainer from "../../../components/containers/MainContainer";
import HeadDataNew from "../../../data/HeadDataNew";

const ThankYouPage = () => (
    <MainContainer>
      <HeadDataNew
        title='Thank You! Customer Concern Form'
        description='Your report has been received.'
      />

      <div>
        <div className='bg-site-gray-light w-full pt-16 px-4'>
          <h1 className='font-display font-extrabold text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>
            Customer Concern Form
          </h1>

          <p className='text-gray-900 text-center font-semibold mt-6'>Quick Survey to report customer concerns with Command Access.</p>
        </div>

        <div className="bg-site-gray-light pt-16 pb-16 w-full">
          <div className="bg-white w-11/12 max-w-2xl pt-16 pb-16 px-2 mx-auto">
            <p className="text-gray-800 text-5xl font-semibold normal-case text-center">Thank You!</p>

            <p className="text-gray-700 text-center mt-12">Your report has been received. We'll be in contact!</p>
          </div>
        </div>
      </div>
    </MainContainer>
)

export default ThankYouPage;